<template>
  <b-card no-body>
    <b-container fluid class="p-2 pb-0 mb-0">
      <b-row class="p-0 mb-0" align-v="center">
        <b-col cols="auto" class="mr-auto pl-1">
          <h1 class="text-primary font-weight-bolder" style="font-size: 2rem">SmartFarm</h1> 
        </b-col>

        <b-col cols="auto" class="mb-0 pb-0">
          <b-img width="100%/7" :src="require('@/assets/images/logo/logo_tera.webp')" fluid alt="logo_tera_solusi">
          </b-img>
        </b-col>
      </b-row>
    </b-container>
    <hr/>

    <b-card-body class="pl-3 pr-3 mt-1 pt-0">      
      <b-card-text>
        List Device id SmartFarm:
      </b-card-text>
      <b-table
        responsive
        :items="items"
        class="mb-0"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BTable, BImg,BRow, BCol, BCard, BContainer, BCardBody, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BImg,
    BCard,
    BContainer,
    BCardBody,
    BCardText,
    BRow,
    BCol,
  },
  data() {
    return {
      items: [
        {no: 1, ear_tag: '013', device_id: 'smarternak_c0de69eed81f0de2'}, 
        {no: 2, ear_tag: '032', device_id: 'smarternak_c0de464312640cfc'}, 
        {no: 3, ear_tag: '5', device_id: 'smarternak_c0deb542c13b78f5'},
        {no: 4, ear_tag: '009', device_id: 'smarternak_c0de22efa6db03c2'}, 
        {no: 5, ear_tag: '026', device_id: 'smarternak_c0dea93a15bd68d6'},      
        {no: 6, ear_tag: '003', device_id: 'smarternak_c0de7a3a9f5da6ea'}, 
        {no: 7, ear_tag: '011', device_id: 'smarternak_c0de9f6d763bc1ce'}, 
        {no: 8, ear_tag: '007', device_id: 'smarternak_c0de369260726fe1'}, 
        {no: 9, ear_tag: '018', device_id: 'smarternak_c0de8211925861f6'}, 
        {no: 10, ear_tag: '028', device_id: 'smarternak_c0de6aea8a7060fe'}, 
        {no: 11, ear_tag: '014', device_id: 'smarternak_c0de8a86680c80db'}, 
        {no: 12, ear_tag: '015', device_id: 'smarternak_c0de86a565dd17ec'}, 
        {no: 13, ear_tag: '024', device_id: 'smarternak_c0de2ed35a7c1ffd'}, 
        {no: 14, ear_tag: '8', device_id: 'smarternak_c0deabcad1badec8'}, 
        {no: 15, ear_tag: '022', device_id: 'smarternak_c0ded4d50a2beae3'}, 
        {no: 16, ear_tag: '030', device_id: 'smarternak_c0deac6d496067e5'}, 
        {no: 17, ear_tag: '008', device_id: 'smarternak_c0de6eddc9d04be8'}, 
        {no: 18, ear_tag: '1', device_id: 'smarternak_c0de6162ac6c71e1'}, 
        {no: 19, ear_tag: '027', device_id: 'smarternak_c0de3f6ec8121bd7'},
        {no: 20, ear_tag: '020', device_id: 'smarternak_c0dec2898a7b7bc3'},
      ],
    }
  },
}

</script>

<style>

</style>
